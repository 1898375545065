import { default as aboutk0EB4ZAo0SMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/about.vue?macro=true";
import { default as accountcfbZIFQ9BxMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/account.vue?macro=true";
import { default as _91slug_93EHa7g1SymTMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/article/[slug].vue?macro=true";
import { default as bloggNXA3gCHZRMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/blog.vue?macro=true";
import { default as careersa9mrkf0SUSMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/careers.vue?macro=true";
import { default as cartmuN2yaZA3eMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/cart.vue?macro=true";
import { default as checkoutHIR6151dmfMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/checkout.vue?macro=true";
import { default as contactbCdlFPGonkMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/contact.vue?macro=true";
import { default as delivery_45regions_45coverage1bHq8kHm0nMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/delivery-regions-coverage.vue?macro=true";
import { default as doctorsL2HoWtaPPeMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/doctors.vue?macro=true";
import { default as eulaaGHvhVy2ozMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/eula.vue?macro=true";
import { default as flowery_45categoriest9R3uGlg3HMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/flowery-categories.vue?macro=true";
import { default as indexXx4yiykhDqMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/index.vue?macro=true";
import { default as live_45sessionsleDWqFxhRjMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/live-sessions.vue?macro=true";
import { default as livenoft9ysNXsMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/live.vue?macro=true";
import { default as locations2pgeDtuH44Meta } from "/home/forge/flowery-bemeir.salve.dev/pages/locations.vue?macro=true";
import { default as loyalty_45programsykUiWo6DJMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/loyalty-program.vue?macro=true";
import { default as mediafTirhSFS0fMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/media.vue?macro=true";
import { default as privacy_45policybjXFlIA9AqMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/privacy-policy.vue?macro=true";
import { default as _91id_93VqlgmNGWE4Meta } from "/home/forge/flowery-bemeir.salve.dev/pages/product/[id].vue?macro=true";
import { default as account_45detailsGHZzXEjMGjMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/account-details.vue?macro=true";
import { default as addressmiSCgm3NrdMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/address.vue?macro=true";
import { default as dashboardQuxS67fce7Meta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/dashboard.vue?macro=true";
import { default as loyaltynQQ6joeg8OMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/loyalty.vue?macro=true";
import { default as medical1JPRtrhgu1Meta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/medical.vue?macro=true";
import { default as _91id_93HOT3PWpmjaMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/order/[id].vue?macro=true";
import { default as ordersQwsJGsE6GTMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/orders.vue?macro=true";
import { default as paymentsJaWqX1wTAZMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/profile/payments.vue?macro=true";
import { default as registrationWlpTubkysEMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/registration.vue?macro=true";
import { default as resourcesCZHjAPcvJbMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/resources.vue?macro=true";
import { default as _91_91slug_93_93jkLBaTnqAWMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/shop/[[slug]].vue?macro=true";
import { default as shoppablegJhfWH8uHeMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/shoppable.vue?macro=true";
import { default as support_45appzvC7uoAIpRMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/support-app.vue?macro=true";
import { default as termsQlL9PF3LsqMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/terms.vue?macro=true";
import { default as verifyOu82OIMZskMeta } from "/home/forge/flowery-bemeir.salve.dev/pages/verify.vue?macro=true";
export default [
  {
    name: aboutk0EB4ZAo0SMeta?.name ?? "about",
    path: aboutk0EB4ZAo0SMeta?.path ?? "/about",
    meta: aboutk0EB4ZAo0SMeta || {},
    alias: aboutk0EB4ZAo0SMeta?.alias || [],
    redirect: aboutk0EB4ZAo0SMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accountcfbZIFQ9BxMeta?.name ?? "account",
    path: accountcfbZIFQ9BxMeta?.path ?? "/account",
    meta: accountcfbZIFQ9BxMeta || {},
    alias: accountcfbZIFQ9BxMeta?.alias || [],
    redirect: accountcfbZIFQ9BxMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EHa7g1SymTMeta?.name ?? "article-slug",
    path: _91slug_93EHa7g1SymTMeta?.path ?? "/article/:slug",
    meta: _91slug_93EHa7g1SymTMeta || {},
    alias: _91slug_93EHa7g1SymTMeta?.alias || [],
    redirect: _91slug_93EHa7g1SymTMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: bloggNXA3gCHZRMeta?.name ?? "blog",
    path: bloggNXA3gCHZRMeta?.path ?? "/blog",
    meta: bloggNXA3gCHZRMeta || {},
    alias: bloggNXA3gCHZRMeta?.alias || [],
    redirect: bloggNXA3gCHZRMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: careersa9mrkf0SUSMeta?.name ?? "careers",
    path: careersa9mrkf0SUSMeta?.path ?? "/careers",
    meta: careersa9mrkf0SUSMeta || {},
    alias: careersa9mrkf0SUSMeta?.alias || [],
    redirect: careersa9mrkf0SUSMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: cartmuN2yaZA3eMeta?.name ?? "cart",
    path: cartmuN2yaZA3eMeta?.path ?? "/cart",
    meta: cartmuN2yaZA3eMeta || {},
    alias: cartmuN2yaZA3eMeta?.alias || [],
    redirect: cartmuN2yaZA3eMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutHIR6151dmfMeta?.name ?? "checkout",
    path: checkoutHIR6151dmfMeta?.path ?? "/checkout",
    meta: checkoutHIR6151dmfMeta || {},
    alias: checkoutHIR6151dmfMeta?.alias || [],
    redirect: checkoutHIR6151dmfMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: contactbCdlFPGonkMeta?.name ?? "contact",
    path: contactbCdlFPGonkMeta?.path ?? "/contact",
    meta: contactbCdlFPGonkMeta || {},
    alias: contactbCdlFPGonkMeta?.alias || [],
    redirect: contactbCdlFPGonkMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: delivery_45regions_45coverage1bHq8kHm0nMeta?.name ?? "delivery-regions-coverage",
    path: delivery_45regions_45coverage1bHq8kHm0nMeta?.path ?? "/delivery-regions-coverage",
    meta: delivery_45regions_45coverage1bHq8kHm0nMeta || {},
    alias: delivery_45regions_45coverage1bHq8kHm0nMeta?.alias || [],
    redirect: delivery_45regions_45coverage1bHq8kHm0nMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/delivery-regions-coverage.vue").then(m => m.default || m)
  },
  {
    name: doctorsL2HoWtaPPeMeta?.name ?? "doctors",
    path: doctorsL2HoWtaPPeMeta?.path ?? "/doctors",
    meta: doctorsL2HoWtaPPeMeta || {},
    alias: doctorsL2HoWtaPPeMeta?.alias || [],
    redirect: doctorsL2HoWtaPPeMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/doctors.vue").then(m => m.default || m)
  },
  {
    name: eulaaGHvhVy2ozMeta?.name ?? "eula",
    path: eulaaGHvhVy2ozMeta?.path ?? "/eula",
    meta: eulaaGHvhVy2ozMeta || {},
    alias: eulaaGHvhVy2ozMeta?.alias || [],
    redirect: eulaaGHvhVy2ozMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/eula.vue").then(m => m.default || m)
  },
  {
    name: flowery_45categoriest9R3uGlg3HMeta?.name ?? "flowery-categories",
    path: flowery_45categoriest9R3uGlg3HMeta?.path ?? "/flowery-categories",
    meta: flowery_45categoriest9R3uGlg3HMeta || {},
    alias: flowery_45categoriest9R3uGlg3HMeta?.alias || [],
    redirect: flowery_45categoriest9R3uGlg3HMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/flowery-categories.vue").then(m => m.default || m)
  },
  {
    name: indexXx4yiykhDqMeta?.name ?? "index",
    path: indexXx4yiykhDqMeta?.path ?? "/",
    meta: indexXx4yiykhDqMeta || {},
    alias: indexXx4yiykhDqMeta?.alias || [],
    redirect: indexXx4yiykhDqMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: live_45sessionsleDWqFxhRjMeta?.name ?? "live-sessions",
    path: live_45sessionsleDWqFxhRjMeta?.path ?? "/live-sessions",
    meta: live_45sessionsleDWqFxhRjMeta || {},
    alias: live_45sessionsleDWqFxhRjMeta?.alias || [],
    redirect: live_45sessionsleDWqFxhRjMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/live-sessions.vue").then(m => m.default || m)
  },
  {
    name: livenoft9ysNXsMeta?.name ?? "live",
    path: livenoft9ysNXsMeta?.path ?? "/live",
    meta: livenoft9ysNXsMeta || {},
    alias: livenoft9ysNXsMeta?.alias || [],
    redirect: livenoft9ysNXsMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/live.vue").then(m => m.default || m)
  },
  {
    name: locations2pgeDtuH44Meta?.name ?? "locations",
    path: locations2pgeDtuH44Meta?.path ?? "/locations",
    meta: locations2pgeDtuH44Meta || {},
    alias: locations2pgeDtuH44Meta?.alias || [],
    redirect: locations2pgeDtuH44Meta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: loyalty_45programsykUiWo6DJMeta?.name ?? "loyalty-program",
    path: loyalty_45programsykUiWo6DJMeta?.path ?? "/loyalty-program",
    meta: loyalty_45programsykUiWo6DJMeta || {},
    alias: loyalty_45programsykUiWo6DJMeta?.alias || [],
    redirect: loyalty_45programsykUiWo6DJMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/loyalty-program.vue").then(m => m.default || m)
  },
  {
    name: mediafTirhSFS0fMeta?.name ?? "media",
    path: mediafTirhSFS0fMeta?.path ?? "/media",
    meta: mediafTirhSFS0fMeta || {},
    alias: mediafTirhSFS0fMeta?.alias || [],
    redirect: mediafTirhSFS0fMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/media.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policybjXFlIA9AqMeta?.name ?? "privacy-policy",
    path: privacy_45policybjXFlIA9AqMeta?.path ?? "/privacy-policy",
    meta: privacy_45policybjXFlIA9AqMeta || {},
    alias: privacy_45policybjXFlIA9AqMeta?.alias || [],
    redirect: privacy_45policybjXFlIA9AqMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VqlgmNGWE4Meta?.name ?? "product-id",
    path: _91id_93VqlgmNGWE4Meta?.path ?? "/product/:id",
    meta: _91id_93VqlgmNGWE4Meta || {},
    alias: _91id_93VqlgmNGWE4Meta?.alias || [],
    redirect: _91id_93VqlgmNGWE4Meta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: account_45detailsGHZzXEjMGjMeta?.name ?? "profile-account-details",
    path: account_45detailsGHZzXEjMGjMeta?.path ?? "/profile/account-details",
    meta: account_45detailsGHZzXEjMGjMeta || {},
    alias: account_45detailsGHZzXEjMGjMeta?.alias || [],
    redirect: account_45detailsGHZzXEjMGjMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/account-details.vue").then(m => m.default || m)
  },
  {
    name: addressmiSCgm3NrdMeta?.name ?? "profile-address",
    path: addressmiSCgm3NrdMeta?.path ?? "/profile/address",
    meta: addressmiSCgm3NrdMeta || {},
    alias: addressmiSCgm3NrdMeta?.alias || [],
    redirect: addressmiSCgm3NrdMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/address.vue").then(m => m.default || m)
  },
  {
    name: dashboardQuxS67fce7Meta?.name ?? "profile-dashboard",
    path: dashboardQuxS67fce7Meta?.path ?? "/profile/dashboard",
    meta: dashboardQuxS67fce7Meta || {},
    alias: dashboardQuxS67fce7Meta?.alias || [],
    redirect: dashboardQuxS67fce7Meta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/dashboard.vue").then(m => m.default || m)
  },
  {
    name: loyaltynQQ6joeg8OMeta?.name ?? "profile-loyalty",
    path: loyaltynQQ6joeg8OMeta?.path ?? "/profile/loyalty",
    meta: loyaltynQQ6joeg8OMeta || {},
    alias: loyaltynQQ6joeg8OMeta?.alias || [],
    redirect: loyaltynQQ6joeg8OMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/loyalty.vue").then(m => m.default || m)
  },
  {
    name: medical1JPRtrhgu1Meta?.name ?? "profile-medical",
    path: medical1JPRtrhgu1Meta?.path ?? "/profile/medical",
    meta: medical1JPRtrhgu1Meta || {},
    alias: medical1JPRtrhgu1Meta?.alias || [],
    redirect: medical1JPRtrhgu1Meta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/medical.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HOT3PWpmjaMeta?.name ?? "profile-order-id",
    path: _91id_93HOT3PWpmjaMeta?.path ?? "/profile/order/:id",
    meta: _91id_93HOT3PWpmjaMeta || {},
    alias: _91id_93HOT3PWpmjaMeta?.alias || [],
    redirect: _91id_93HOT3PWpmjaMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/order/[id].vue").then(m => m.default || m)
  },
  {
    name: ordersQwsJGsE6GTMeta?.name ?? "profile-orders",
    path: ordersQwsJGsE6GTMeta?.path ?? "/profile/orders",
    meta: ordersQwsJGsE6GTMeta || {},
    alias: ordersQwsJGsE6GTMeta?.alias || [],
    redirect: ordersQwsJGsE6GTMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/orders.vue").then(m => m.default || m)
  },
  {
    name: paymentsJaWqX1wTAZMeta?.name ?? "profile-payments",
    path: paymentsJaWqX1wTAZMeta?.path ?? "/profile/payments",
    meta: paymentsJaWqX1wTAZMeta || {},
    alias: paymentsJaWqX1wTAZMeta?.alias || [],
    redirect: paymentsJaWqX1wTAZMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/profile/payments.vue").then(m => m.default || m)
  },
  {
    name: registrationWlpTubkysEMeta?.name ?? "registration",
    path: registrationWlpTubkysEMeta?.path ?? "/registration",
    meta: registrationWlpTubkysEMeta || {},
    alias: registrationWlpTubkysEMeta?.alias || [],
    redirect: registrationWlpTubkysEMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: resourcesCZHjAPcvJbMeta?.name ?? "resources",
    path: resourcesCZHjAPcvJbMeta?.path ?? "/resources",
    meta: resourcesCZHjAPcvJbMeta || {},
    alias: resourcesCZHjAPcvJbMeta?.alias || [],
    redirect: resourcesCZHjAPcvJbMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/resources.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93jkLBaTnqAWMeta?.name ?? "shop-slug",
    path: _91_91slug_93_93jkLBaTnqAWMeta?.path ?? "/shop/:slug?",
    meta: _91_91slug_93_93jkLBaTnqAWMeta || {},
    alias: _91_91slug_93_93jkLBaTnqAWMeta?.alias || [],
    redirect: _91_91slug_93_93jkLBaTnqAWMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/shop/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: shoppablegJhfWH8uHeMeta?.name ?? "shoppable",
    path: shoppablegJhfWH8uHeMeta?.path ?? "/shoppable",
    meta: shoppablegJhfWH8uHeMeta || {},
    alias: shoppablegJhfWH8uHeMeta?.alias || [],
    redirect: shoppablegJhfWH8uHeMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/shoppable.vue").then(m => m.default || m)
  },
  {
    name: support_45appzvC7uoAIpRMeta?.name ?? "support-app",
    path: support_45appzvC7uoAIpRMeta?.path ?? "/support-app",
    meta: support_45appzvC7uoAIpRMeta || {},
    alias: support_45appzvC7uoAIpRMeta?.alias || [],
    redirect: support_45appzvC7uoAIpRMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/support-app.vue").then(m => m.default || m)
  },
  {
    name: termsQlL9PF3LsqMeta?.name ?? "terms",
    path: termsQlL9PF3LsqMeta?.path ?? "/terms",
    meta: termsQlL9PF3LsqMeta || {},
    alias: termsQlL9PF3LsqMeta?.alias || [],
    redirect: termsQlL9PF3LsqMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: verifyOu82OIMZskMeta?.name ?? "verify",
    path: verifyOu82OIMZskMeta?.path ?? "/verify",
    meta: verifyOu82OIMZskMeta || {},
    alias: verifyOu82OIMZskMeta?.alias || [],
    redirect: verifyOu82OIMZskMeta?.redirect || undefined,
    component: () => import("/home/forge/flowery-bemeir.salve.dev/pages/verify.vue").then(m => m.default || m)
  }
]