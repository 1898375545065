export default {
  GET_HEADER_DATA: (state) => {
    return state.headerData
  },

  /**
   * Returns true if navbar is open, false if it's closed
   * @returns {boolean}
   */
  xIsNavbarOpen(state) {
    return state.mobileNavbarOpen
  },

  /**
   * Returns true if menu is open, false if it's closed
   * @returns {boolean}
   */
  xIsShowMobileMenu(state) {
    return state.showMobileMenu
  },

  xIsSelectedOptionsStatus(state) {
    return state.xIsSelectedOptions
  }
}
