export default () => ({
  // Not sure what this is, pulled from the server
  headerData: {},

  // If true the mobile navbar will be shown
  mobileNavbarOpen: false,

  // If true the mobile menu will be shown
  showMobileMenu: false,
  
  xIsSelectedOptions: false,
})
